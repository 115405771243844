var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterClients',{on:{"filtersClient":_vm.filtersClient}})],1),(_vm.showTable)?_c('v-data-table',{staticClass:"elevation-0 px-6 pb-4 pt-10",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, _vm.clientsLength]},"headers":_vm.headers,"items":_vm.clients,"options":_vm.options,"server-items-length":_vm.totalClients,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Clientes")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('download')),expression:"permissions('download')"}],attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.exportExcel}},[_vm._v("mdi-download")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions('editCompanies')==true || _vm.permissions('deleteCompanies')==true)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editCompanies')),expression:"permissions('editCompanies')"}],on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editCompanies')),expression:"permissions('editCompanies')"}],on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status!=null)?_c('v-chip',_vm._g(_vm._b({staticClass:"pa-2",style:({'background':'linear-gradient(0deg, rgb(255 255 255 / 85%), rgb(255 255 255 / 85%)) rgb(0, 198, 255)', 'background-color':item.status.color, 'color':item.status.color}),attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.status.name))]):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((_vm.statusesLists),function(status,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.changeStatus(item.id, status.id)}}},[_vm._v(_vm._s(status.name))])],1)],1)],1)}),1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))]),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.razon_social!=null && item.name.toLowerCase()!=item.razon_social.toLowerCase()),expression:"item.razon_social!=null && item.name.toLowerCase()!=item.razon_social.toLowerCase()"}]},[_vm._v(_vm._s(item.razon_social))])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ path: '/clients/client/'+ item.id}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de clientes aún ")]},proxy:true}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e25200","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('create',{on:{"closeCreateDialogCompany":_vm.closeCreateDialogCompany}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"company":_vm.company},on:{"closeDialogEditCompany":_vm.closeDialogEditCompany}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar esta empresa? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteCompany()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }